import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "output"]

  initialize() {
    let _this = this;

    window.addEventListener('click', function(e){   

      document.querySelectorAll(".close-on-click-outside").forEach(function (el) { 
        if (!el.contains(e.target)){
          _this.hideAll();
        }
      });
      
    });


    $(".header-dropdown .nav-toggle").click(function(e) {
      let active = $(this).hasClass("active");

      $(".header-dropdown .nav-toggle.active").removeClass('active');
      $(".header-dropdown .sub-nav.active").removeClass('active');

      if (active == false) {
        $(this).addClass("active");
        $(this).next(".sub-nav").addClass("active");
      }

    });

  }

  openMainDropdown() {
    let is_open = $('#main-dropdown').hasClass("active") || $("#header #menu-button").hasClass("active");

    this.hideAll();

    if (!is_open) {
      this.showMainDropdown();
    }

  }

  openMenuDropdown(event) {
    let type = event.currentTarget.dataset.type;

    if (type != undefined) {

      let is_open = $(`#${type}-dropdown`).hasClass("active");

      this.hideAll();
      
      if (!is_open) {
        this.showDropdown(type);
      }

    }
  }


  openUserMenu() {
    let is_open = $("#header .user").hasClass("active");

    this.hideAll();

    if (!is_open) {
      this.showUserMenu();
    }

  }

  openSearch() {
    if ($("body").hasClass("search_controller") == true && $("body").hasClass("index_action") == true) {
      $("#search-field input").focus();
    } else {
      let is_open = $("#header .search").hasClass("active");

      this.hideAll();

      if(!is_open) {
        this.showSearch();
      }

    }

  }


  showMainDropdown() {
    $("#header #menu-button").addClass("active");
    $("#header").addClass("dropdown-opened");
    $("#main-dropdown").addClass("active");

    setTimeout(function() {
      $("#main-dropdown").addClass("close-on-click-outside");
    }, 200);

  }

  showDropdown(key) {
    $("#header").addClass("dropdown-opened");
    $("#header #" + key + "-navigation-item").addClass("active");
    $("#" + key + "-dropdown").addClass("active");

    setTimeout(function() {
      $("#" + key + "-dropdown").addClass("close-on-click-outside");
    }, 200);

  }

  showUserMenu() {
    $("#header .user").addClass("active");
    $("#header .user .menu-dropdown").addClass("active");

    setTimeout(function() {
      $("#header .user .menu-dropdown").addClass("close-on-click-outside");
    }, 200);
    
  }

  showSearch() {
    $("#header .search").addClass('active');
    $("#header").addClass("dropdown-opened");
    $("#search_popup").addClass('active');
    $("#search_popup input#search_key").focus();

    setTimeout(function() {
      $("#search_popup").addClass("close-on-click-outside");
    }, 200);

  }


  hideAll() {
    let _this = this;
    let types = ["community", "innovations", "implementations", "services", "news", "contact", "spotlights"];

    $(".close-on-click-outside").removeClass("close-on-click-outside");
    this.hideMainDropdown();
    this.hideUserMenu();
    this.hideSearch();
    types.forEach(function(type) {
      _this.hideDropdown(type);
    });
    
  }


  hideMainDropdown() {
    $("#header #menu-button").removeClass("active");
    $("#header").removeClass("dropdown-opened");
    $("#main-dropdown").removeClass("active");
  }

  hideDropdown(key) {
    $("#header").removeClass("dropdown-opened");
    $("#header #" + key + "-navigation-item").removeClass("active");
    $("#" + key + "-dropdown").removeClass("active");
  }

  hideUserMenu() {
    $("#header .user").removeClass("active");
    $("#header .user .menu-dropdown").removeClass("active");
  }

  hideSearch() {
    $("#header .search").removeClass('active');
    $("#header").removeClass("dropdown-opened");
    $("#search_popup").removeClass('active');
  }
  
} 
